import React from 'react';

const Rules = () => {
  return (
    <div>
      Rules
    </div>
  );
};

export default Rules;